<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="10">
          <a-col :span="6">
            <a-form-item>
              <a-input-search placeholder="请输入标题" v-model="queryParam.title"></a-input-search>
            </a-form-item>
          </a-col>
          <!--           <a-col :span="6">
            <a-form-item label="发布人">
              <a-input placeholder="请输入发布人" v-model="queryParam.sender"></a-input>
            </a-form-item>
          </a-col> -->

          <!--           <a-col :span="8">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
            </span>
          </a-col> -->
          <a-col :span="4" style="display:flex;float: right;justify-content: end;">
            <a-button v-if="radioValue == '1'" type="primary" @click="readAll" icon="book">全标已读</a-button>
            <a-button v-else type="primary" @click="clearReadAll" icon="book">清空已读</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div style="padding-top:10px;padding-bottom:20px;">
      <a-radio-group v-model="radioValue">
        <a-radio value="1">未读</a-radio>
        <a-radio value="2">已读</a-radio>
      </a-radio-group>
    </div>
    <a-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :pagination="false"
      :loading="loading"
      :customRow="customRow"
      @change="handleTableChange"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 446px)' }"
    >
      <!-- :pagination="ipagination" -->
      <!-- <span slot="action" slot-scope="text, record">
        <a @click="showAnnouncement(record)">查看</a>
      </span> -->
      <template slot="readFlag" slot-scope="text, record">
        <div @click.stop v-if="record.children && record.total > 50 && record.isOpen">
          <a-pagination
            size="small"
            :showLessItems="true"
            :default-current="1"
            :defaultPageSize="50"
            :total="record.total - 0"
            @change="
              (page, pageSize) => {
                treeTablePageChange(page, pageSize, record)
              }
            "
          />
        </div>
        <div v-else-if="record.readFlag != 'hasChild'">
          {{ record.readFlag == '1' ? '已读' : '未读' }}
        </div>
      </template>
    </a-table>
    <show-announcement @openRemark="openRemark" ref="ShowAnnouncement"></show-announcement>
    <dynamic-notice ref="showDynamNotice" :path="openPath" :formData="formData" />
    <a-modal
      :title="`【备注  ${taskDetail.productionName}-${taskDetail.chapterOrder}】`"
      placement="right"
      :width="700"
      :footer="null"
      wrapClassName="drawer-layout"
      :visible="drawerVisible"
      @cancel="onClose"
    >
      <remark ref="remark" height="500px" :isMyTask="true" :taskDetail="taskDetail" :params="taskDetail" />
    </a-modal>
  </a-card>
</template>

<script>
import { getAction, putAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import ShowAnnouncement from '@/components/tools/ShowAnnouncement'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import DynamicNotice from '../../components/tools/DynamicNotice'
import { batchStyleInsertHeader, filterObj, handleArrayParams } from '@/utils/util'
import remark from '@/views/system/details/mytasks/modules/remark'
export default {
  name: 'UserAnnouncementList',
  mixins: [JeecgListMixin],
  components: {
    DynamicNotice,
    ShowAnnouncement,
    remark
  },
  data() {
    return {
      description: '系统通告表管理页面',
      queryParam: {},
      radioValue: '1',
      columns: [
        /*         {
          title: '应用场景',
          align: 'center',
          width: 180,
          dataIndex: 'scenes',
          customRender: function(text) {
            switch (text) {
              case 'WELCOME':
                return '【新账号注册】'
              case 'TASK_READY':
                return '【新任务】'
              case 'TASK_START':
                return '【可开始】'
              case 'TASK_BEEN_KPI':
                return '【已审核】'
              case 'TASK_TO_KPI':
                return '【未审核】'
              case 'GRADE':
                return '【评级】'
              case 'TASK_WARNING':
                return '【预警】'
              case 'TASK_REARRANGE':
                return '【不能完成】'
              case 'TASK_DELAY':
                return '【拖稿】'
              case 'TASK_REJECT':
                return '【驳回】'
              case 'TASK_DELAY_FINISH':
                return '【拖稿完成】'
              case 'SETTLE_BOSS_CONFIRMED':
                return '【结算】'
              case 'SETTLE_BOSS_PAYED':
                return '【支付】'
              case 'TASK_SCHEDULE_CHANGE_APPLY':
                return '【可安排数量】'
              case 'USER_LEAVE_APPLY':
                return '【请假审批】'
              case 'QUESTION_ANSWER_REPLY':
                return '【帮助与反馈】'
              case 'PRODUCTION_CHAPTER_NOTE':
                return '【备注】'
            }
          }
        }, */
        {
          title: '标题',
          align: 'center',
          width: 450,
          dataIndex: 'title'
        },
        {
          title: '消息类型',
          align: 'center',
          dataIndex: 'msgCategory',
          customRender: function(text) {
            if (text == '1') {
              return '通知公告'
            } else if (text == '2') {
              return '系统消息'
            } else if (text == '3') {
              return '私信'
            } else {
              return text
            }
          },
          width: 200
        },
        {
          title: '发布人',
          align: 'center',
          dataIndex: 'sender',
          width: 200
        },
        {
          title: '发布时间',
          align: 'center',
          width: 185,
          dataIndex: 'sendTime'
        },
        {
          title: '优先级',
          align: 'center',
          dataIndex: 'priority',
          customRender: function(text) {
            if (text == 'LOW') {
              return '低'
            } else if (text == 'MIDDLE') {
              return '中'
            } else if (text == 'HIGH') {
              return '高'
            } else {
              return text
            }
          },
          width: 200
        },
        {
          title: '阅读状态',
          align: 'center',
          dataIndex: 'readFlag',
          /*           customRender: function(text) {
            if (text == '0') {
              return '未读'
            } else if (text == '1') {
              return '已读'
            } else {
              return text
            }
          }, */
          scopedSlots: { customRender: 'readFlag' },
          width: 200
        }
        /* ,{
          title: '操作',
          dataIndex: 'action',
          align:"center",
          scopedSlots: { customRender: 'action' },
        } */
      ],
      msgTableData: [
        {
          scenes: 'PRODUCTION_CHAPTER_NOTE',
          id: 'PRODUCTION_CHAPTER_NOTE',
          children: [],
          readFlag: 'hasChild',
          isOpen: false,
          total: 0
        }
      ],
      url: {
        list: '/sys/sysAnnouncementSend/getMyAnnouncementSend',
        editCementSend: 'sys/sysAnnouncementSend/editByAnntIdAndUserId',
        readAllMsg: 'sys/sysAnnouncementSend/readAll?msgCategory=2'
      },
      loading: false,
      expandedRowKeys: [],
      openPath: '',
      formData: '',
      taskDetail: {},
      drawerVisible: false
    }
  },
  watch: {
    radioValue() {
      this.loadData()
    }
  },
  methods: {
    onClose() {
      this.drawerVisible = false
    },
    async openRemark(id) {
      var userId = Vue.ls.get(USER_INFO).id
      this.taskDetail.chapterId = id
      let chapterRes = await getAction('/production/get_chapter', { id, userId })
      if (!chapterRes.success) {
        this.$message.error('获取章节信息失败')
        return
      }
      this.taskDetail.chapterOrder = chapterRes?.data?.sequenceNo
      this.taskDetail.productionId = chapterRes?.data?.productionId
      this.taskDetail.stageList = chapterRes?.data?.stageList
      this.taskDetail.stageId = chapterRes?.data?.stageId
      let productRes = await getAction('/production/get', { id: this.taskDetail.productionId })
      if (!productRes.success) {
        this.$message.error('获取作品信息失败')
        return
      }
      this.taskDetail.productionName = productRes.data?.productionName || productRes.data?.foreignName
      this.drawerVisible = true
      const timeOut = setTimeout(() => {
        if (this.$refs.remark) {
          this.$refs.remark.getRemarkList()
          clearTimeout(timeOut)
        }
      }, 100)
      console.log('openRemark', { id, 'this.taskDetail': this.taskDetail })
    },
    clearReadAll() {
      var that = this
      that.$confirm({
        title: '确认操作',
        content: '是否清空已读消息?',
        onOk: function() {
          putAction('/sys/sysAnnouncementSend/clearHasRead?msgCategory=2').then(res => {
            if (res.success) {
              that.$message.success('清空成功')
              that.loadData()
              that.syncHeadNotic()
            }
          })
        }
      })
    },
    expandedRowsChange(keys) {
      this.expandedRowKeys = keys
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = /* params.pageSize */ -1
      if (this.radioValue == '1') {
        params.readFlag = '0'
      } else {
        params.readFlag = '1'
      }
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          this.dataSource = []
          this.msgTableData = [
            {
              scenes: 'PRODUCTION_CHAPTER_NOTE',
              id: 'PRODUCTION_CHAPTER_NOTE',
              children: [],
              readFlag: 'hasChild',
              isOpen: false,
              total: 0
            }
          ]
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          this.$emit('loadData', this.dataSource)
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        /*         this.dataSource.forEach(item => {
          this.msgTableData.forEach(i => {
            if (item.scene == i.scenes) {
              i.children.push(item)
            }
          })
        }) */
        this.dataSource = this.dataSource.filter(item => item.scene == 'PRODUCTION_CHAPTER_NOTE')
        console.log(
          'TASK_SCHEDULE_CHANGE_APPLY',
          this.dataSource.filter(item => item.scene == 'PRODUCTION_CHAPTER_NOTE')
        )
        this.msgTableData = this.msgTableData.filter(item => item.children.length)
        this.loading = false
      })
    },
    treeTablePageChange(page, pageSize, row) {
      row.page = page
      let keys = this.expandedRowKeys
      this.expandedRowKeys = []
      setTimeout(() => {
        this.expandedRowKeys = keys
        var params = this.getQueryParams()
        params.pageSize = 50
        params.size = 50
        params.current = page
        params.scenes = [row.scenes]
        if (this.radioValue == '1') {
          params.readFlag = '0'
        } else {
          params.readFlag = '1'
        }
        getAction(this.url.list + '?' + new URLSearchParams(handleArrayParams(params)).toString()).then(res => {
          console.log(res)
          if (res.success) {
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            row.children = res.data.records
            row.total = res.data.total
          }
        })
      }, 0)
    },
    handleDetail: function(record) {
      this.$refs.sysAnnouncementModal.detail(record)
      this.$refs.sysAnnouncementModal.title = '查看'
    },
    customRow(record, index) {
      return {
        on: {
          click: () => {
            if (record.children) return
            this.showAnnouncement(record)
          }
        }
      }
    },
    expandTable(isOpen, row) {
      row.isOpen = isOpen
      if (isOpen) {
        var params = this.getQueryParams()
        params.pageSize = 50
        params.current = 1
        params.size = 50
        params.scenes = [row.scenes]
        if (this.radioValue == '1') {
          params.readFlag = '0'
        } else {
          params.readFlag = '1'
        }
        getAction(this.url.list + '?' + new URLSearchParams(handleArrayParams(params)).toString()).then(res => {
          console.log(res)
          if (res.success) {
            //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
            row.children = res.data.records
            row.total = res.data.total
          }
        })
      }
    },
    showAnnouncement(record) {
      putAction(this.url.editCementSend, { anntId: record.anntId }).then(res => {
        if (res.success) {
          this.loadData()
          this.syncHeadNotic(record.anntId)
        }
      })
      if (record.openType === 'component') {
        this.openPath = record.openPage
        this.formData = { id: record.busId }
        this.$refs.showDynamNotice.detail()
      } else {
        this.$refs.ShowAnnouncement.detail(record)
      }
    },
    syncHeadNotic(anntId) {
      getAction('sys/annountCement/syncNotic', { anntId: anntId })
    },
    readAll() {
      var that = this
      /*       that.$confirm({
        title: '确认操作',
        content: '是否全部标注已读?',
        onOk: function() {
          that.loading = true
          putAction(that.url.readAllMsg).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.loadData()
              that.syncHeadNotic()
            }
          })
        }
      }) */
      putAction(that.url.readAllMsg).then(res => {
        if (res.success) {
          that.$message.success(res.message)
          that.loadData()
          that.syncHeadNotic()
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
.ant-card-body .table-operator {
  margin-bottom: 18px;
}
.anty-row-operator button {
  margin: 0 5px;
}
.ant-btn-danger {
  background-color: #ffffff;
}
z .ant-modal-cust-warp {
  height: 100%;
}
.ant-modal-cust-warp .ant-modal-body {
  height: calc(100% - 110px) !important;
  overflow-y: auto;
}
.ant-modal-cust-warp .ant-modal-content {
  height: 90% !important;
  overflow-y: hidden;
}
/* /deep/ .ant-table-tbody .ant-table-row td:first-child {
  text-align: start !important;
} */
</style>
